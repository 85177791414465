@use '../../index.scss';
.product-list {
  // grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  grid-template-columns: 1fr;
  gap: 10px;
  display: grid;
  list-style: none;

  .product {
    a {
      text-decoration: none;
    }
    img {
      width: 100%;
    }
    .product-details {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .product-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .text {
          font-weight: 500;
        }
      }
      .price {
        font-weight: 500;
      }
    }

    .gender {
      color: rgba(0, 0, 0, 0.568);
      text-transform: capitalize;
    }
  }
}

@media (min-width: 550px) {
  .product-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 800px) {
  .product-list {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 900px) {
  .product-list {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
