footer {
	flex-shrink: 0;
	margin-top: 4rem;
	.text {
		font-size: 13px;
	}
	.footer-container {
		.footer-top {
			gap: 15px;
			grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));

			div {
				.title {
					text-transform: uppercase;
					font-size: 16px;
					margin-bottom: 0px;
				}

				ul li {
					list-style: none;
					text-transform: uppercase;
					font-size: 13px;
				}
			}
			.footer-icons div {
				font-size: 20px;
				font-weight: 600;
				display: flex;
				gap: 10px;
			}
		}
		.footer-bottom {
			margin-bottom: 10px;
			small {
				font-size: 11px;
			}
		}
	}
}
