.checkout {
  .checkout-container {
    .btn-container {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      button {
        width: 250px;
        height: 50px;
        font-size: 16px;
        font-weight: 600;
      }
    }

    .form-control {
      display: grid;
    }
    .form-wrapper {
      display: grid;
    }
    .input-header {
      display: flex;
      flex-direction: column;

      small {
        color: red;
        font-weight: 600;
      }
      .title {
        text-transform: uppercase;
      }
    }
    input,
    select {
      padding-left: 10px;
      padding-top: 5px;
      padding: 5px 10px 0px 10px;
      height: 40px;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      outline: none;
      border: 1px solid #e1e1e1;
      margin-bottom: 20px;
      border-radius: 5px;
      width: 100%;
    }
    .personal-details-container {
      .personal-details-data {
        display: grid;
      }
    }
    .shipping-details-container {
      .shipping-details-data {
        select {
          background-color: white;
          outline: none;

          option {
            outline: none;
            border: none;
          }
        }
      }
    }
  }
}

@media (min-width: 954px) {
  .checkout {
    .checkout-container {
      .form-wrapper {
        grid-template-columns: 1fr 2fr;
        gap: 20px;
        .form-control {
          margin-top: 20px;
        }
        .form-control {
          grid-template-columns: 1fr 1fr;
          gap: 10px;
        }
        .payment-data {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }
  }
}
