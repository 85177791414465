@use '../../index.scss';
nav {
	min-height: 3.5rem;
	display: flex;
	.nav-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.nav-left-content a {
			font-weight: index.$font-weight-bold;
			text-decoration: none;
			span {
				color: red;
			}
		}
		.nav-right-content {
			display: flex;
			align-items: center;
			gap: index.$mg-2;
			font-size: index.$text-font-size;
			.cart-icon-wrapper {
				font-size: 24px;
				position: relative;
				.cart-icon {
					text-transform: none;
					color: index.$primary-color;
					display: flex;
				}
				.inCart {
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 10px;
					position: absolute;
					color: white;
					background-color: black;
					height: 13px;
					width: 13px;
					bottom: -2px;
					right: 0;
					border-radius: 50%;
				}
			}
			.menu {
				display: flex;
				overflow: hidden;
				background-color: white;
				z-index: 1000;
				gap: index.$mg-2;
				height: 100%;
				li {
					list-style: none;
					position: relative;
					a {
						text-decoration: none;
						color: black;
						text-transform: capitalize;
						font-size: 15px;
						text-transform: uppercase;
						letter-spacing: 1.5px;
						font-weight: 500;
						&:after {
							background: none repeat scroll 0 0 transparent;
							bottom: 0;
							content: '';
							display: block;
							height: 2px;
							left: 50%;
							position: absolute;
							background: #000;
							transition: width 0.3s ease 0s, left 0.3s ease 0s;
							width: 0;
						}
						&:hover:after {
							width: 100%;
							left: 0;
						}
					}
				}
				.account-container {
					display: flex;
					height: 100%;
					justify-content: center;
					align-items: center;
					font-size: 20px;
					gap: 3px;
					&:after {
						background-color: transparent;
					}
				}
			}
			.burger-icon-container {
				display: none;
				width: 25px;
				height: 25px;
			}
		}
	}
}

// === menu media query
@media only screen and (max-width: 700px) {
	nav {
		.nav-container {
			.nav-right-content {
				.menu {
					position: absolute;
					max-height: 0;
					width: 100%;
					top: 3.5rem;
					left: 0;
					transition: all 0.35s ease-in-out;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					z-index: 1000;

					&.isOpen {
						max-height: 100vh;
					}
				}
				.burger-icon-container {
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					&:hover {
						cursor: pointer;
					}
					.burger {
						width: 100%;
						height: 1.5px;
						&.isOpen:before {
							transform: rotate(-45deg);
						}
						&.isOpen:after {
							transform: rotate(45deg);
						}
						&:before {
							content: '';
							position: absolute;
							width: 100%;
							height: 1.5px;
							background-color: black;
							transform: translateY(3px);
							transition: all 0.3s ease-out;
						}
						&:after {
							content: '';
							position: absolute;
							width: 100%;
							height: 1.5px;
							background-color: black;
							transform: translateY(-3px);
							transition: all 0.3s ease-out;
						}
					}
				}
			}
		}
	}
}
