.general-info-wrapper {
	.sub-title {
		text-transform: capitalize;
		font-size: 14px;
		color: black;
		font-weight: 600;
	}
	.text {
		font-size: 14px;
		padding-right: 10px;
	}
	.general-info-content {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px 0;
		&:hover {
			cursor: pointer;
		}

		button {
			padding: 5px;
			border: none;
			outline: none;
			padding: 0;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			color: black;
			background-color: white;
			font-size: 15px;

			&:hover {
				cursor: pointer;
			}
		}
	}
}
