.shop-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: calc(100vh - 4rem);
  align-items: flex-start;
  // background-color: blue;
}

@media (min-width: 800px) {
  .shop-container {
    flex-direction: row;
  }
}
