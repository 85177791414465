.cart-item {
	display: flex;
	padding: 1rem;
	background-color: #f6f6f6;
	justify-content: space-between;
	height: 6.5rem;

	.cart-item-left {
		display: flex;
		gap: 1rem;
		line-height: 1;
		.cart-item-info {
			display: flex;
			flex-direction: column;

			justify-content: space-between;

			.title {
				font-size: 13px;
				line-height: 1;
				text-transform: capitalize;
			}
			p {
				font-size: 10px;
				line-height: 1.5;
				text-transform: capitalize;
				margin-bottom: 0.5rem;
			}
			label {
				font-size: 12px;
				margin-right: 5px;
				text-transform: capitalize;
			}
			select {
				outline: none;
				border: none;
				background-color: white;
				border: 1px solid rgba(0, 0, 0, 0.253);
				width: 40px;
				option {
					background-color: white;
					width: 40px;
					border-radius: 0;
					outline: none;
					border: none;
				}
			}
		}

		.image-container {
			display: block;
			width: 70px;
			height: auto;
			background-color: #f6f6f6;

			img {
				width: 100%;
			}
		}
	}
	.cart-item-right {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: 100%;

		p {
			font-size: 15px;
			font-weight: 500;

			padding: 0;
			margin: 0;
		}
		button {
			background-color: transparent;
			border: none;
			font-size: 20px;
			&:hover {
				cursor: pointer;
			}
		}
	}
}

@media (min-width: 800px) {
	.cart-item {
		.cart-item-left {
			.cart-item-info {
				.title {
					font-size: 15px;
				}
				.text {
					font-size: 12px;
				}
			}
		}
	}
}
