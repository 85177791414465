// // ==== product images ==== //

.mySwiper2 {
  width: 100%;

  .swiper-wrapper {
    width: 100%;
    .swiper-slide {
      width: 100% !important;
      img {
        width: 100%;
      }
    }
  }
}

// ==== thumbnails ====
.swiper-container-thumbs {
  margin: 0;
  padding: 0;
  width: 100%;

  .swiper-wrapper {
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: space-between;
    .swiper-slide {
      width: 24% !important;
      margin: 0 !important;
      opacity: 0.5;
      img {
        display: block;
        width: 100%;
        height: auto;
      }

      // margin-top: 10px;
      .swiper-slide-visible {
        margin: 0;
        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .swiper-slide.swiper-slide-thumb-active {
      opacity: 1;
    }
  }
}
