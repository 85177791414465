.filter-by-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  input {
    border: 1px solid #999;
    padding: 5px;
    outline: none;
    border-radius: 5px;
    font-size: 16px;
  }
  .filter-by-title {
    font-size: 12px;
  }

  .price-range-container {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .filter-by-container {
    width: 100%;
  }
}
